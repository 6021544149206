import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// devices routing
const DefaultSetting = Loadable(lazy(() => import('./DefaultSetting')));

// ==============================|| MAIN ROUTING ||============================== //

const DefaultRoutes = {
    path: 'default',
    children: [
        {
            path: 'defaultSetting',
            element: <DefaultSetting />
        }
    ]
};

export default DefaultRoutes;
