import io from 'socket.io-client';
let socket = null;

export const initializeSocket = (url) => {
    socket = io.connect(url, { autoConnect: false });
    return socket;
};

export const getSocket = () => {
    return socket;
};
