import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// devices routing
const Devices = Loadable(lazy(() => import('./devices/Device')));
const ConvertedDataRequest = Loadable(lazy(() => import('./converted_data/ConvertedDataRequest')));
const HttpServer = Loadable(lazy(() => import('./http-server-configuration/Server')));
const MQTTServer = Loadable(lazy(() => import('./mqtt-server-configuration/Server')));

// ==============================|| MAIN ROUTING ||============================== //

const HttpsDataReceiverRoutes = {
    path: 'dataReceiver',
    children: [
        {
            path: 'requests',
            element: <Devices />
        },
        {
            path: 'convertedDataRequests',
            element: <ConvertedDataRequest />
        },
        {
            path: 'httpserver',
            element: <HttpServer />
        },
        {
            path: 'mqttserver',
            element: <MQTTServer />
        }
    ]
};

export default HttpsDataReceiverRoutes;
