import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// devices routing
const Customers = Loadable(lazy(() => import('./customers/Customers')));

// ==============================|| MAIN ROUTING ||============================== //

const CustomerManagementRoutes = {
    path: 'customerManagement',
    children: [
        {
            path: 'customers',
            element: <Customers />
        }
    ]
};

export default CustomerManagementRoutes;
