import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// devices routing
const User = Loadable(lazy(() => import('./users/User')));

// ==============================|| MAIN ROUTING ||============================== //

const UserManagementRoutes = {
    path: 'userManagement',
    children: [
        {
            path: 'users',
            element: <User />
        }
    ]
};

export default UserManagementRoutes;
