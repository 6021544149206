import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// TriggerConfig routing
const TriggerConfig = Loadable(lazy(() => import('./TriggerConfig')));
const ChartConfig = Loadable(lazy(() => import('./chart-config/ChartConfig')));

// ==============================|| MAIN ROUTING ||============================== //

const TroggerRoutes = {
    path: 'trigger',
    children: [
        {
            path: 'config',
            element: <TriggerConfig />
        },
        {
            path: 'chart-config',
            element: <ChartConfig />
        }
    ]
};

export default TroggerRoutes;
