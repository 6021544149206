// action - customization reducer
export const LOGIN_SUCCESS = '@login/LOGIN_SUCCESS';
export const LOGIN_ERROR = '@login/LOGIN_ERROR';
export const SET_MULTIPLE_COMPANIES = '@login/SET_MULTIPLE_COMPANIES';
export const RESET_MULTIPLE_COMPANIES = '@login/RESET_MULTIPLE_COMPANIES';
export const FIRST_TIME_USER_LOGIN = '@login/FIRST_TIME_USER_LOGIN';
export const USER_NOT_FOUND = '@login/USER_NOT_FOUND';
export const USER_LOGGED_OUT = '@login/USER_LOGGED_OUT';
import firebaseService from './firebaseService';
export const SET_DATEFORMAT = '@login/SET_DATEFORMAT';
export const SET_DATATOSERVERGATEWAY = '@login/SET_DATATOSERVERGATEWAY';

import { getSocket } from '../../Socket';

export function logoutUser() {
    return (dispatch) => {
        firebaseService.signOut();

        localStorage.removeItem('companyid');
        localStorage.removeItem('token');
        const socket = getSocket();
        if (socket) {
            socket.disconnect();
        }

        dispatch({
            type: USER_LOGGED_OUT
        });
    };
}

export function resetMultipleCompanies() {
    return (dispatch) =>
        dispatch({
            type: RESET_MULTIPLE_COMPANIES
        });
}

export function setCompanyDateFormate(dateFormat, dateFormatId) {
    return {
        type: SET_DATEFORMAT,
        payload: { dateFormat, dateFormatId }
    };
}

export function setDataToServerGateway(isIiotGateway, isHttps, isMqtts, isOpcUa, httpsDataType, mqttsDataType) {
    return {
        type: SET_DATEFORMAT,
        payload: { isIiotGateway, isHttps, isMqtts, isOpcUa, httpsDataType, mqttsDataType }
    };
}
