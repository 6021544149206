import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/Logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {
    const theme = useTheme();

    return (
        <img
            src={logo}
            alt="Industrial IoT"
            style={{ width: props.width ? props.width : '200px', height: props.height ? props.height : '50px' }}
        />
    );
};

export default Logo;
