import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// devices routing
const DataGateway = Loadable(lazy(() => import('./DataGateway')));

// ==============================|| MAIN ROUTING ||============================== //

const DataGatewayRoutes = {
    path: 'datatoserver',
    children: [
        {
            path: 'gateway',
            element: <DataGateway />
        }
    ]
};

export default DataGatewayRoutes;
