import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

// material-ui
import { ButtonBase, Box, Grid, Typography } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { MENU_OPEN } from 'store/actions';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const theme = useTheme();
    const defaultId = useSelector((state) => state.customization.defaultId);
    const userDetail = useSelector((state) => state.login.userDetail);
    const dispatch = useDispatch();
    return (
        <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={config.defaultPath}>
            {/* <Logo /> */}
            {/* <h2>IIOT DataReader</h2> */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h2">IIOT DataReader</Typography>
                    {/* <Logo width={'120px'} height={'30px'} /> */}
                    <Typography
                        variant="h5"
                        sx={{
                            color: theme.palette.grey[500],
                            mt: 0.3
                        }}
                    >
                        {userDetail && userDetail.clientName ? userDetail && userDetail.clientName : ''}
                    </Typography>
                </Grid>
            </Grid>
        </ButtonBase>
    );
};

export default LogoSection;
