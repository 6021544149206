module.exports = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,

    api: {
        urlHost: 'http://localhost:5001',
        //urlHost: 'https://app.iiotdatareader.com',
        basePath: '/api',
        baseMediaPath: '/screenMedia'
    },

    firebaseConfig: {
        apiKey: 'AIzaSyCbfRuQyJz08FADt2C8bQcQUbxgOPlQ-K4',
        authDomain: 'uat-industrial-iot.firebaseapp.com',
        projectId: 'uat-industrial-iot',
        storageBucket: 'uat-industrial-iot.appspot.com',
        messagingSenderId: '226050783139',
        appId: '1:226050783139:web:f4b5fa430eb8c748478c16',
        measurementId: 'G-886BP6494K'
    }
};
