import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// routes
import DataMonitorRoutes from '../views/datamonitor/DataMonitorRoutes';
import SettingsRoutes from '../views/settings/SettingsRoutes';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />
        },
        DataMonitorRoutes,
        SettingsRoutes
    ]
};

export default MainRoutes;
