module.exports = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,

    api: {
        //urlHost: 'https://ec2-43-205-215-107.ap-south-1.compute.amazonaws.com',
        urlHost: 'https://app.iiotdatareader.com',
        basePath: '/api',
        baseMediaPath: '/screenMedia'
    },
    firebaseConfig: {
        // apiKey           : "YOUR_API_KEY",
        // authDomain       : "your-app.firebaseapp.com",
        // databaseURL      : "https://your-app.firebaseio.com",
        // projectId        : "your-app",
        // storageBucket    : "your-app.appspot.com",
        // messagingSenderId: "YOUR_MESSAGING_SENDER_ID"
        apiKey: 'AIzaSyBCOYq6oZC1PtxCMiVCJFRqshugoZ8OzQs',
        authDomain: 'industrial-iot-b6aa0.firebaseapp.com',
        projectId: 'industrial-iot-b6aa0',
        storageBucket: 'industrial-iot-b6aa0.appspot.com',
        messagingSenderId: '954243639770',
        appId: '1:954243639770:web:d5b9fef28af025e691b8e3',
        measurementId: 'G-X13BQW8PZ1'
    }
};
//exports prod files
