import { initializeApp } from 'firebase/app';
import {
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updatePassword
} from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import { getMessaging, isSupported } from 'firebase/messaging';
import config from '../../config';

class firebaseService {
    constructor() {
        // console.log('### firebaseService-constructor');
        this.initializeAppIfNeeded();
    }

    initializeAppIfNeeded() {
        // console.log('### initializeAppIfNeeded', config.firebaseConfig);
        if (!initializeApp?.apps?.length) {
            const app = initializeApp(config.firebaseConfig);
            this.db = getDatabase(app);
            this.auth = getAuth(app);

            if (isSupported()) {
                this.messaging = getMessaging();
            }
        }
    }

    firebaseSignInWithEmailAndPassword = (email, password) => {
        return new Promise(async (resolve, reject) => {
            signInWithEmailAndPassword(this.auth, email, password)
                .then((userCredential) => {
                    resolve(userCredential);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    signInWithGoogle = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const provider = new GoogleAuthProvider();
                provider.setCustomParameters({ prompt: 'select_account' });
                signInWithPopup(this.auth, provider)
                    .then((result) => {
                        // console.log('####-result', result);
                        resolve(result.user);
                    })
                    .catch((error) => {
                        // console.log('####-error', error);
                        reject(error);
                    });
            } catch (error) {
                console.log('####-error', error);
                reject(error);
            }
        });
    };

    resetPasswordFirstTimeLogin = (password) => {
        return updatePassword(this.auth.currentUser, password);
    };

    forgetPassword = (email) => {
        return sendPasswordResetEmail(this.auth, email);
    };

    updateUserData = (user) => {
        if (!initializeApp.apps.length) {
            return;
        }
        return set(ref(this.db, `users/${user.uid}`), user);
    };

    firebaseOnAuthStateChanged = (callback) => {
        if (!this.auth) {
            return;
        }
        onAuthStateChanged(this.auth, callback);
    };

    signOut = () => {
        if (!this.auth) {
            return;
        }
        signOut(this.auth).then(
            () => {
                console.log('Signed Out');
            },
            (error) => {
                console.log('Sign Out Error', error);
            }
        );
    };

    sendVerificationEmail = () => {
        return sendEmailVerification(this.auth.currentUser);
    };
}

const instance = new firebaseService();

export default instance;
