// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconChartHistogram,
    Icon24Hours,
    IconAntenna,
    IconHeartRateMonitor
} from '@tabler/icons-react';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconChartHistogram,
    Icon24Hours,
    IconAntenna,
    IconHeartRateMonitor
};

// ==============================|| REALTIME MENU ITEMS ||============================== //

const datamonitor = {
    id: 'datamonitor',
    title: 'Data Monitor',
    type: 'group',
    children: [
        {
            id: 'realtime',
            title: 'Realtime',
            type: 'collapse',
            icon: icons.Icon24Hours,
            children: [
                {
                    id: 'realtime-table-data',
                    title: 'Table View',
                    type: 'item',
                    url: '/datamonitor/realtime/table-data',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'scada-screens',
            title: 'Screens',
            type: 'item',
            url: '/datamonitor/screens/table-view',
            icon: icons.IconHeartRateMonitor,
            breadcrumbs: false
        },
        {
            id: 'polinginterval',
            title: 'Historical Data',
            type: 'collapse',
            icon: icons.IconChartHistogram,
            children: [
                {
                    id: 'poling-interval-table-data',
                    title: 'Table View',
                    type: 'item',
                    url: '/datamonitor/historicalData/table-view',
                    breadcrumbs: false
                },
                {
                    id: 'poling-interval-chart-dataV2',
                    title: 'Graphical View',
                    type: 'item',
                    url: '/datamonitor/historicalData/chart-viewV2',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'trigger',
            title: 'Trigger',
            type: 'collapse',
            icon: icons.IconAntenna,
            children: [
                {
                    id: 'trigger-table-data',
                    title: 'Table View',
                    type: 'item',
                    url: '/datamonitor/trigger/table-view',
                    breadcrumbs: false
                },
                {
                    id: 'trigger-chart-dataV2',
                    title: 'Graphical View',
                    type: 'item',
                    url: '/datamonitor/trigger/chart-viewV2',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default datamonitor;
