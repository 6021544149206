import * as LoginActionTypes from './loginactions';

export const initialState = {
    isLoggedIn: false,
    error: {
        email: null,
        password: null
    },
    firstLogin: false,
    userNotFound: false,
    multipleCompanies: false,
    companies: [],
    userDetail: {}
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LoginActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                userDetail: action.userDetail,
                isLoggedIn: true
            };
        case LoginActionTypes.LOGIN_ERROR:
            return {
                isLoggedIn: false,
                error: action.payload
            };
        case LoginActionTypes.SET_MULTIPLE_COMPANIES:
            return {
                ...state,
                multipleCompanies: true,
                companies: action.companies
            };
        case LoginActionTypes.RESET_MULTIPLE_COMPANIES:
            return {
                ...state,
                multipleCompanies: false,
                companies: []
            };
        case LoginActionTypes.FIRST_TIME_USER_LOGIN:
            return {
                ...state,
                userDetail: action.userDetail,
                firstLogin: true
            };
        case LoginActionTypes.USER_NOT_FOUND:
            return {
                ...state,
                userNotFound: true
            };
        case LoginActionTypes.USER_LOGGED_OUT:
            return initialState;
        case LoginActionTypes.SET_DATEFORMAT: {
            return {
                ...state,
                userDetail: { ...state.userDetail, ...action.payload }
            };
        }
        case LoginActionTypes.SET_DATATOSERVERGATEWAY: {
            return {
                ...state,
                userDetail: { ...state.userDetail, ...action.payload }
            };
        }
        default:
            return state;
    }
};

export default loginReducer;
