// assets
import { IconDashboard } from '@tabler/icons-react';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const parentClientDashboard = {
    id: 'parentClientDashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/datamonitor/screens/table-view',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default parentClientDashboard;
