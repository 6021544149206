import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// RealtimeTableConfig routing
const RealtimeScreenConfig = Loadable(lazy(() => import('./tableConfiguration/RealtimeScreenConfig')));

// ==============================|| MAIN ROUTING ||============================== //

const RealtimeRoutes = {
    path: 'realtime',
    children: [
        {
            path: 'screenconfig',
            element: <RealtimeScreenConfig />
        }
    ]
};

export default RealtimeRoutes;
