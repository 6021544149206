import dashboard from './dashboard';
import datamonitor from './datamonitor';
import settings from './settings';
import parentClientDashboard from './parentClientDashboard';
import parentClientSettings from './parentClientSettings';

// Function to filter menu items based on user permissions
const filterMenuItems = (items, permissions) => {
    const hasPermission = (itemPermissions) => {
        if (!itemPermissions) return true; // No permission required
        // Check if permissions is an array or a single value
        if (Array.isArray(itemPermissions)) {
            // Check if any of the permissions are granted
            return itemPermissions.some((permission) => permissions[permission] == true);
        } else {
            // Single permission check
            return permissions && permissions[itemPermissions] == true;
        }
    };

    return items
        .filter((item) => hasPermission(item.permissions))
        .map((item) => {
            if (item.children) {
                return {
                    ...item,
                    children: filterMenuItems(item.children, permissions)
                };
            }
            return item;
        });
};

// ==============================|| MENU ITEMS ||============================== //

// Function to get menu items based on user details
const getMenuItems = (userDetail) => {
    if (!userDetail) return { items: [] }; // Return empty if no user detail

    // Extract permissions
    const permissions = {
        isSettingAccess: userDetail.isSettingAccess,
        isParentCustomer: userDetail.parentClientId ? false : true,
        isIiotGateway: userDetail.isIiotGateway,
        isHttps: userDetail.isHttps,
        isMqtts: userDetail.isMqtts,
        isOpcUa: userDetail.isOpcUa,
        isRawData: userDetail.httpsDataType == '16BIT_INTEGER' || userDetail.mqttsDataType == '16BIT_INTEGER' ? true : false,
        isPreComputedData: userDetail.httpsDataType == 'PRE_COMPUTED' || userDetail.mqttsDataType == 'PRE_COMPUTED' ? true : false
    };

    // console.log('####-permissions=', permissions);

    if (userDetail.parentClientId) {
        return {
            items: filterMenuItems([dashboard, datamonitor, settings], permissions)
        };
    } else {
        return {
            items: filterMenuItems([parentClientDashboard, parentClientSettings], permissions)
        };
    }

    // if (userDetail && userDetail.parentClientId) {
    //     return {
    //         items: [dashboard, datamonitor, settings]
    //     };
    // } else {
    //     return {
    //         items: [parentClientDashboard, parentClientSettings]
    //     };
    // }
};

export default getMenuItems;
