import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SplashScreen from 'SplashScreen';
import firebaseService from './store/login/firebaseService';
import AuthLoginService from './views/authentication/login/LoginService';

const Auth = (props) => {
    const [waitAuthCheck, setWaitAuthCheck] = useState(true);
    const dispatch = useDispatch();

    const firebaseCheck = function () {
        return new Promise(async (resolve, reject) => {
            // console.log('###---firebaseCheck');

            firebaseService.firebaseOnAuthStateChanged((authUser) => {
                if (authUser) {
                    // console.log('####-firebaseOnAuthStateChanged-authUser-', authUser);

                    AuthLoginService.doClientUserLoginWithFirebaseIdToken()
                        .then((respClientLogin) => {
                            resolve(respClientLogin);
                        })
                        .catch((err) => {
                            console.log('err-', err);
                        });
                } else {
                    resolve();
                }
            });
        });
    };

    const checkFirebaseAuth = () => {
        return firebaseCheck().then((authUser) => {
            setWaitAuthCheck(false);
        });
    };

    useEffect(() => {
        checkFirebaseAuth();
    }, []);

    return <>{waitAuthCheck ? <SplashScreen /> : <React.Fragment children={props.children} />}</>;
};

export default Auth;
