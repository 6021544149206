import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// project imports
import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

    let navigate = useNavigate();
    useEffect(() => {
        if (isLoggedIn) {
            return navigate('/dashboard');
        }
    }, [isLoggedIn, navigate]);

    return (
        <>
            <Outlet />
            {/* <Customization /> */}
        </>
    );
};

export default MinimalLayout;
